.users-growth {
  display: flex;
  align-items: center;
}
.users-growth-count {
  margin-left: 5%;
}
.date-picker-field {
  width: 30%;
}
.charts-container {
  display: flex;
  flex-direction: row;
}
.all-statistic {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.all-statistic-item {
  display: flex;
  justify-content: space-between;
}
.statistic-title {
  text-align: center;
  margin-top: 3%;
}
.statistic-card-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.charts {
  display: flex;
  flex-wrap: wrap;
}

.chart-item {
  flex-grow: 1;
  min-width: 50%;
}

@media (max-width: 1200px) {
  .chart-item {
    min-width: 100%;
  }
}
.hr {
  width: 100%;
}
