.field-container {
    display: flex;
    align-items: center;
}

.text-container {
    margin-left: 2%;
}

.card-container {
    display: flex;
}

.service-container {
    width: 25%;
}

.rewards-container {
    width: 35%;
}

.general-info-container {
    width: 40%;
    margin-left: 5%;
}

.accordion-container {
    max-height: 250px;
    overflow-y: auto;
}